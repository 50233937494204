import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { ChildImageSharpFixed } from "../types"

type BackgroundStaticQuery = {
  file: {
    childImageSharp: ChildImageSharpFixed
  }
}

const BGPattern = () => {
  const background = useStaticQuery<BackgroundStaticQuery>(graphql`
    query {
      file(name: { eq: "background" }) {
        childImageSharp {
          fixed(width: 1920, height: 560, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return(
      <Img fixed={background.file.childImageSharp.fixed} />
  )
}

export default BGPattern